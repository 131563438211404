import React from "react";
// import "./AboutPage.scss";

const AboutPage = () => {
  return (
    <div className="d-flex align-center justify-center">
      <div>About page</div>
    </div>
  );
};

export default AboutPage;
